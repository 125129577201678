import React from 'react';
import EditOffering from '../common/components/EditOffering';
import { makeStyles, createStyles, Theme, Container, Paper } from '@material-ui/core';
import BackButton from 'common/components/BackButton';

interface EditOfferingPageProps {
  done: () => void;
}

const EditOfferingPage: React.FC<EditOfferingPageProps> = props => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Paper elevation={1} className={classes.paper}>
        <BackButton back={props.done} />
        <EditOffering done={props.done} title='Edit Your Gift Card' />
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 0'
      }
    },
    paper: {
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    }
  })
);

export default EditOfferingPage;
