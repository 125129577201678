import useSafeFetch from 'common/hooks/useSafeFetch';
import React, { useState, useRef, useEffect } from 'react';
import GiftCardOffering from 'common/types/GiftCardOffering';

interface GiftCardOfferingsContextType {
  offerings: GiftCardOffering[] | undefined;
  refreshOfferings: () => void;
  loading: boolean;
  error: boolean;
}

export const OfferingsContext = React.createContext<GiftCardOfferingsContextType>({
  offerings: [],
  refreshOfferings: () => null,
  error: false,
  loading: false
});

interface State {
  offerings: GiftCardOffering[] | undefined;
  error: boolean;
}

const GiftCardOfferingsContextProvider: React.FC = props => {
  const [state, setState] = useState<State>({
    offerings: undefined,
    error: false
  });
  const { get } = useSafeFetch();

  const refreshOfferings = useRef(async () => {
    if (state.offerings !== undefined || state.error) {
      setState({ offerings: undefined, error: false });
    }

    try {
      const response = await get(`${process.env.REACT_APP_PORTAL}/offerings`);
      const offerings = await response.json();
      setState(s => ({ ...s, offerings }));
    } catch (e) {
      if (!e.cancelled && !e.unauthenticated) {
        setState(s => ({ ...s, error: true }));
      }
    }
  });

  useEffect(() => {
    refreshOfferings.current();
  }, []);

  return (
    <OfferingsContext.Provider
      value={{
        offerings: state.offerings,
        refreshOfferings: refreshOfferings.current,
        error: state.error,
        loading: state.offerings === undefined && !state.error
      }}
    >
      {props.children}
    </OfferingsContext.Provider>
  );
};

export default GiftCardOfferingsContextProvider;
