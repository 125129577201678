import React from 'react';
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import QrCode from '../assets/qr-code.svg';

interface ChargeButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  redeem: () => void;
}

const ChargeButton: React.FC<ChargeButtonProps> = props => {
  const { redeem, ...other } = props;
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    props.redeem();
  };

  return (
    <div {...other}>
      <Button onClick={handleClick} className={classes.button} variant='contained' color='secondary'>
        <img src={QrCode} alt='Scan QR Code' className={classes.icon} />
        Charge Gift Card
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '4.3rem'
    },
    icon: {
      width: '50px',
      marginRight: theme.spacing(1)
    }
  })
);

export default ChargeButton;
