import React, { useEffect, useState, useContext } from 'react';
import { CssBaseline, CircularProgress, Container } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import AppBar from '../AppBar';
import AppTheme from '../AppTheme';
import Setup from 'setup';
import Dashboard from 'dashboard';
import { SwitchTransition } from 'react-transition-group';
import FadeTransition from 'common/components/FadeTransition';
import EditOfferingPage from 'editofferingpage';
import Redeem from 'redeem';
import GiftCardOfferingsContextProvider, { OfferingsContext } from 'core/context/OfferingsContext';
import UserContextProvider, { UserContext, UserType } from 'core/context/UserContext';
import useSafeFetch from 'common/hooks/useSafeFetch';
import { Alert } from '@material-ui/lab';
import SnackbarContextProvider from 'core/context/SnackbarContext';
import AppSnackbar from '../AppSnackbar';
import { Auth } from 'auth';
import NotFound from '../404';

const App: React.FC = () => {
  return (
    <Router>
      <AppTheme>
        <CssBaseline />
        <UserContextProvider>
          <SnackbarContextProvider>
            <Switch>
              <Route exact path='/login' component={Auth} />
              <Route exact path='/signup' component={Auth} />
              <Route exact path='/' component={AuthenticatedApp} />
              <Route path='*' component={NotFound} />
            </Switch>
          </SnackbarContextProvider>
        </UserContextProvider>
      </AppTheme>
    </Router>
  );
};

const AuthenticatedApp: React.FC = () => {
  return (
    <GiftCardOfferingsContextProvider>
      <AppContent />
    </GiftCardOfferingsContextProvider>
  );
};

declare global {
  interface Window {
    FS: any;
  }
}

interface SetupState {
  paymentIsSetup?: boolean;
  offeringIsSetup?: boolean;
  stripeUrl?: string;
}

type Severity = 'error' | 'success' | 'info' | 'warning';

const identifyFullstory = (user: UserType | undefined) => {
  if (!user) return;
  (window as Window).FS.identify(user.uid, {
    displayName: user.businessName,
    email: user.email,
    username: user.username,
  });
};

const AppContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const offeringsContext = useContext(OfferingsContext);
  const userContext = useContext(UserContext);
  const [page, setPage] = useState<'editOffering' | 'redeem'>();
  const [error, setError] = useState<string>();
  const [setup, setSetup] = useState<SetupState>({});
  const { get } = useSafeFetch();

  useEffect(() => {
    const fetchSetupInfo = async () => {
      try {
        const response = await get(`${process.env.REACT_APP_PORTAL}/offerings/payments`);
        const { issetup, stripe_oath_url } = await response.json();
        setSetup((s) => ({
          ...s,
          paymentIsSetup: issetup,
          stripeUrl: stripe_oath_url,
        }));
      } catch (e) {
        if (!e.cancelled && !e.unauthenticated) {
          setError(
            'There was a problem checking the status of your Stripe account. If this problem persists, please contact our team for support.'
          );
          setSetup((s) => ({
            ...s,
            paymentIsSetup: true,
          }));
        }
      }
    };

    fetchSetupInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = userContext;
  useEffect(() => {
    identifyFullstory(user);
  }, [user]);

  useEffect(() => {
    let offeringIsSetup = Boolean(offeringsContext.offerings?.length);
    if (
      offeringsContext.offerings != undefined &&
      offeringsContext.offerings.length > 0 &&
      offeringsContext.offerings[0].configured === false
    ) {
      offeringIsSetup = false;
    }

    setSetup((s) => ({
      ...s,
      offeringIsSetup: offeringIsSetup,
    }));
  }, [offeringsContext.offerings]);

  if (
    userContext.loading ||
    offeringsContext.loading ||
    setup.offeringIsSetup === undefined ||
    setup.paymentIsSetup === undefined
  ) {
    return (
      <>
        {error ? (
          <Container>
            <Alert className={classes.alert} elevation={0} variant='filled' severity='error'>
              {error}
            </Alert>
          </Container>
        ) : null}
        <div className={classes.fullPage}>
          <CircularProgress size='6rem' />
        </div>
      </>
    );
  }

  const showShareStep = history.location.search === '?share=true';
  const setupStep = setup.offeringIsSetup ? (setup.paymentIsSetup ? 2 : 1) : 0;
  const editOffering = () => setPage('editOffering');
  const redeem = () => setPage('redeem');
  const backToDash = () => setPage(undefined);
  const setupComplete = setup.offeringIsSetup && setup.paymentIsSetup && !showShareStep;
  const goToNextSetupStep = () => {
    if (setupStep === 0 && !setup.paymentIsSetup) {
      setSetup((s) => ({ ...s, offeringIsSetup: true }));
    } else {
      backToDash();
    }
  };

  return (
    <AppTheme>
      <div className={classes.root}>
        <AppBar />
        <Container>
          {error ? (
            <Alert className={classes.alert} elevation={0} variant='filled' severity='error'>
              {error}
            </Alert>
          ) : null}
          <SwitchTransition>
            <FadeTransition key={page || `${setupComplete}`}>
              {page === 'editOffering' ? (
                <EditOfferingPage key='editOffering' done={backToDash} />
              ) : page === 'redeem' ? (
                <Redeem done={backToDash} />
              ) : !setupComplete ? (
                <Setup
                  key='setup'
                  activeStep={setupStep!!}
                  proceedToNextStep={goToNextSetupStep}
                  stripeIsSetup={setup.paymentIsSetup}
                  offeringIsSetup={setup.offeringIsSetup}
                  stripeUrl={setup.stripeUrl!!}
                />
              ) : (
                <Dashboard key='dashboard' editOffering={editOffering} redeem={redeem} stripeUrl={setup.stripeUrl!!} />
              )}
            </FadeTransition>
          </SwitchTransition>
          <AppSnackbar />
        </Container>
      </div>
    </AppTheme>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      'minWidth': '320px',
      'paddingTop': '56px',
      '@media (min-width: 600px)': {
        paddingTop: '64px !important',
      },
      '@media (min-width: 0px) and (orientation: landscape)': {
        paddingTop: '48px',
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: theme.mixins.toolbar,
    fullPage: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    alert: {
      marginTop: theme.spacing(2),
    },
  })
);

export default App;
