import React from 'react';
import { Container, Button, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import GiftCardOffering from 'common/types/GiftCardOffering';
import LinkCard from 'dashboard/components/LinkCard';
import { useHistory } from 'react-router-dom';
import SocialMediaShareButtons from 'common/components/SocialMediaShareButtons';

interface SocialMediaPageProps {
  offering?: GiftCardOffering;
}

const SocialMediaPage: React.FC<SocialMediaPageProps> = props => {
  const history = useHistory();
  const classes = useStyles();
  const done = () => history.push('/');

  return (
    <>
      <Container className={classes.root}>
        <Typography variant='h4' component='h1'>
          Your gift card is ready for sharing!
        </Typography>
        <section className={classes.section}>
          <Typography variant='body1' component='p' className={classes.p}>
            Share this page with customers & followers and encourage them to share it within their own social networks.
          </Typography>
          <SocialMediaShareButtons />
        </section>
        <section className={classes.section}>
          <Typography variant='body1' component='p' className={classes.p}>
            or copy this link anywhere:
          </Typography>
          <LinkCard offeringUri={props.offering?.uri} className={classes.linkCard} />
        </section>
        <div className={classes.buttonBar}>
          <Button onClick={done} variant='contained' color='secondary'>
            Done
          </Button>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      textAlign: 'center'
    },
    alert: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    buttonBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '2rem'
    },
    linkContainer: {
      marginTop: theme.spacing(4)
    },
    link: {
      'textDecoration': 'none',
      '& > button': {
        width: '250px'
      }
    },
    shareButtons: {
      'textAlign': 'center',
      '& button + button': {
        marginLeft: '5px'
      }
    },
    linkCard: {
      display: 'inline-block'
    },
    section: {
      margin: '3rem auto'
    },
    p: {
      paddingBottom: '1rem'
    }
  })
);

export default SocialMediaPage;
