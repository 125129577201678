import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon
} from 'react-share';
import { OfferingsContext } from 'core/context/OfferingsContext';

const SocialMediaShareButtons: React.FC = () => {
  const classes = useStyles();
  const { offerings } = useContext(OfferingsContext);
  const title = 'Support our business by purchasing a gift card today.';
  const summary = 'Support our business by purchasing a gift card today.';
  const url = `${process.env.REACT_APP_PORTAL}/giftcards/${offerings?.[0].uri}`;

  return (
    <div className={classes.shareButtons}>
      <TwitterShareButton url={url} title='' hashtags={['giftcards', 'supportlocalbusiness', 'covid19']}>
        <TwitterIcon size={40} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} title={title} quote={summary}>
        <FacebookIcon size={40} round />
      </FacebookShareButton>
      <LinkedinShareButton url={url} title={title} summary={summary}>
        <LinkedinIcon size={40} round />
      </LinkedinShareButton>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shareButtons: {
      'textAlign': 'center',
      '& button + button': {
        marginLeft: '5px'
      }
    }
  })
);

export default SocialMediaShareButtons;
