import { Button, Grid, InputLabel, Typography, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useState, useContext, useEffect } from 'react';
import GiftCardOffering from 'common/types/GiftCardOffering';
import BootstrapInput from 'common/components/BootstrapInput';
import ImageUpload, { Image } from 'common/components/ImageUpload';
import useSafeFetch from 'common/hooks/useSafeFetch';
import ColorPicker from '../ColorPicker';
import OfferingPreview from '../OfferingPreview';
import { red } from '@material-ui/core/colors';
import { OfferingsContext } from 'core/context/OfferingsContext';
import { SnackbarContext } from 'core/context/SnackbarContext';
import LoadingButton from 'common/components/LoadingButton';
import { UserContext } from 'core/context/UserContext';

interface GiftCardPageProps {
  done: () => void;
  title: string;
  create?: boolean;
}

const DEFAULT_OFFERING: Partial<GiftCardOffering> = {
  fontColor: '#000',
  backgroundColor: '#FFF',
  description: 'Thank you for supporting our business!',
  title: 'Gift Card',
};

const EditOffering: React.FC<GiftCardPageProps> = (props) => {
  const classes = useStyles();
  const { post, put } = useSafeFetch();
  const { offerings, refreshOfferings } = useContext(OfferingsContext);
  const { user } = useContext(UserContext);
  const { showSnackbarAlert } = useContext(SnackbarContext);
  const [offering, setOffering] = useState<Partial<GiftCardOffering>>(
    offerings?.[0] || { ...DEFAULT_OFFERING, logoText: user?.businessName || '' }
  );
  const [activeFontColor, setActiveFontColor] = useState<string | undefined>(undefined);
  const [activeBkgColor, setActiveBkgColor] = useState<string | undefined>(undefined);
  const [logoFile, setLogoFile] = useState<Image>();
  const [error, setError] = useState(false);
  const [requiredErrors, setRequiredErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOffering((o) => ({ ...o, logoText: user?.businessName || '' }));
  }, [user]);

  const updateErrors = (): boolean => {
    const errors = [];
    if (
      !offering.logoUrl &&
      !logoFile &&
      (!offering.logoText || offering.logoText?.toLowerCase() === 'business name')
    ) {
      errors.push('logo');
    }

    setRequiredErrors(errors);
    return Boolean(errors.length);
  };

  const onSubmit = async () => {
    const hasErrors = updateErrors();
    if (hasErrors) {
      return;
    }

    let { uuid, ...payload } = offering || {};
    payload.configured = true;
    const isCreate = !uuid;
    try {
      setLoading(true);
      if (uuid) {
        await put(`${process.env.REACT_APP_PORTAL}/offerings/${uuid}`, {
          body: JSON.stringify(payload),
        });
      } else {
        const response = await post(`${process.env.REACT_APP_PORTAL}/offerings`, {
          body: JSON.stringify(payload),
        });
        uuid = (await response.json()).uuid;
      }

      // POST logo if necessary
      if (logoFile) {
        const formData = new FormData();
        formData.append('file', logoFile.file as any);
        await post(`${process.env.REACT_APP_PORTAL}/offerings/${uuid}/logo`, {
          // @ts-ignore
          body: new URLSearchParams(formData),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
      }

      setLoading(false);
      refreshOfferings();
      if (!isCreate) {
        showSnackbarAlert('Gift card was successfuly updated.', 'success');
      }
      props.done();
    } catch (e) {
      if (!e.cancelled) {
        setLoading(false);
        setError(true);
      }
    }
  };

  const onFontColorClose = () => {
    if (activeFontColor) {
      setOffering((p) => ({ ...p, fontColor: activeFontColor }));
    }
    closePicker();
  };
  const onFontColorChange = (hex: string | undefined) => setActiveFontColor(hex);
  const onBkgColorClose = () => {
    if (activeBkgColor) {
      setOffering((p) => ({ ...p, backgroundColor: activeBkgColor }));
    }
    closePicker();
  };
  const onBkgColorChange = (hex: string | undefined) => setActiveBkgColor(hex);

  const closePicker = () => {
    setActiveFontColor(undefined);
    setActiveBkgColor(undefined);
  };

  const onImageChange = (file: Image) => setLogoFile(file);

  const update = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOffering((p) => ({ ...p, [key]: value }));
  };

  const removeLogoFile = () => setLogoFile(undefined);

  const imageThumbnail = logoFile?.file || offering.logoUrl;

  const hasLogoError = requiredErrors.includes('logo');

  return (
    <>
      {error && <Alert severity='error'>Something went wrong. Try again.</Alert>}
      <Typography variant='h4' component='h1' className={classes.title}>
        {props.title}
      </Typography>
      <p className={classes.center}>
        This is how the gift card will appear in the customer's Apple wallet. If the customer does not have Apple
        wallet, they will download this as an image.
      </p>
      <Grid container className={classes.grid} spacing={8} justify='center'>
        <Grid item>
          <OfferingPreview
            balanceType='original'
            offering={{
              ...offering,
              logoUrl: (logoFile?.file as any) || offering.logoUrl,
              fontColor: activeFontColor || offering.fontColor,
              backgroundColor: activeBkgColor || offering.backgroundColor,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <InputLabel error={hasLogoError} className={classes.label}>
                Logo
              </InputLabel>
              <div className={classes.imageLogoRow}>
                {imageThumbnail ? (
                  <div className={classes.logoContainer}>
                    <Paper elevation={2} className={classes.logoThumbnailPaper}>
                      <img src={imageThumbnail as any} className={classes.logoThumbnail} alt='logo' />
                    </Paper>
                    {logoFile && (
                      <Button variant='contained' className={classes.removeButton} onClick={removeLogoFile}>
                        Remove
                      </Button>
                    )}
                  </div>
                ) : null}
                <ImageUpload onChange={onImageChange} />
              </div>
            </Grid>
            <Grid item>
              <InputLabel error={hasLogoError} className={classes.label}>
                Logo Text
              </InputLabel>
              <BootstrapInput
                className={classes.input}
                defaultValue={offering.logoText}
                id='offering-logo'
                onChange={update('logoText')}
              />
              {hasLogoError && (
                <Typography variant='subtitle2' className={classes.errorText}>
                  A logo image or logo text must be provided.
                </Typography>
              )}
            </Grid>
            <Grid item>
              <InputLabel className={classes.label} htmlFor='offering-text'>
                Title
              </InputLabel>
              <BootstrapInput
                className={classes.input}
                defaultValue={offering.title}
                id='offering-title'
                onChange={update('title')}
              />
            </Grid>
            <Grid item>
              <InputLabel className={classes.label} htmlFor='offering-text'>
                Description
              </InputLabel>
              <BootstrapInput
                className={classes.input}
                defaultValue={offering.description}
                id='offering-description'
                onChange={update('description')}
                multiline
              />
            </Grid>
            <Grid item>
              <div className={classes.colorsRow}>
                <div>
                  <InputLabel className={classes.label}>Font Color</InputLabel>
                  <ColorPicker
                    value={activeFontColor || offering.fontColor!!}
                    onClose={onFontColorClose}
                    onChange={onFontColorChange}
                  />
                </div>
                <div>
                  <InputLabel className={classes.label}>Background Color</InputLabel>
                  <ColorPicker
                    value={activeBkgColor || offering.backgroundColor!!}
                    onClose={onBkgColorClose}
                    onChange={onBkgColorChange}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttonBar}>
        {!Boolean(props.create) && <Button onClick={props.done}>Cancel</Button>}
        <LoadingButton loading={loading} onClick={onSubmit} variant='contained' color='secondary'>
          {props.create ? 'Next' : 'Save'}
        </LoadingButton>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    center: {
      textAlign: 'center',
    },
    edit: {
      flex: 1,
    },
    title: {
      paddingTop: theme.spacing(2),
      textAlign: 'center',
      margin: '0 auto',
    },
    preview: {
      flex: 1,
      width: '100%',
      height: '100%',
    },
    grid: {
      padding: theme.spacing(4),
    },
    divider: {
      marginRight: theme.spacing(4),
    },
    label: {
      fontWeight: 700,
      fontSize: '0.9rem',
    },
    buttonGroup: {
      'marginLeft': theme.spacing(2),
      '.MuiToggleButton-root': {
        height: '2rem',
      },
    },
    buttonBar: {
      'display': 'flex',
      'justifyContent': 'flex-end',
      'marginBottom': '2rem',
      '& button': {
        margin: theme.spacing(1),
      },
    },
    input: {
      'width': '300px',
      '& > input': {
        width: '100%',
        minHeight: 'unset!important',
      },
      '& > textarea': {
        width: '100%',
        height: '82px !important',
      },
    },
    errorText: {
      color: red[500],
      marginTop: theme.spacing(1),
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    logoThumbnailPaper: {
      marginRight: theme.spacing(2),
      height: '50px',
      width: '50px',
    },
    logoThumbnail: {
      height: '50px',
      width: '50px',
      objectFit: 'contain',
    },
    imageLogoRow: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
    },
    removeButton: {
      width: '50px',
      minWidth: '50px',
      lineHeight: '11px',
      height: '20px',
      fontSize: '10px',
      fontWeight: 700,
      marginTop: '5px',
    },
    colorsRow: {
      'display': 'flex',
      '& > div': {
        paddingRight: theme.spacing(2),
        textAlign: 'center',
      },
    },
  })
);

export default EditOffering;
