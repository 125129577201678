import React, { useContext, useState } from 'react';
import { Formik, Form, useFormikContext, Field } from 'formik';
import * as Yup from 'yup';
import { SignUpFormContext } from './SignUpFormContext';
import { TextField } from '@material-ui/core';
import { useStyles } from 'auth/styles';
import LoadingButton from 'common/components/LoadingButton';

const DEFAULT_ERR_MSG = 'An error occurred. Please try again.';

const AccountForm: React.FC = () => {
  const formContext = useContext(SignUpFormContext);
  const [errorOnSubmit, setErrorOnSubmit] = useState<string>();

  return (
    <Formik
      initialValues={{
        username: formContext.value.businessname?.toLowerCase().replace(/[^\w-]/g, ''),
        password: '',
        repeatPassword: '',
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Username field is required'),
        password: Yup.string()
          .required('Password field is required')
          .matches(/.{8,}/, 'The password must be at least 8 characters long.')
          .matches(/.*[!@#$%^&*]/, 'Password must contain at least one special character.'),
        repeatPassword: Yup.string()
          .required('Please confirm the password.')
          .test('passwords-match', 'Passwords must match.', function (value) {
            return this.parent.password === value;
          }),
      })}
      onSubmit={async ({ username, password }, { setSubmitting, resetForm }) => {
        const loginInfo = {
          username,
          password,
        };
        try {
          setSubmitting(true);
          setErrorOnSubmit(undefined);
          const response: Response = await formContext.createAccount(loginInfo);
          if (!response.ok) {
            setSubmitting(false);
            setErrorOnSubmit(getErrorMessageByStatus(response));
          } else if (typeof window !== 'undefined') {
            window.location.href = '/';
          }
        } catch (err) {
          setSubmitting(false);
          setErrorOnSubmit(DEFAULT_ERR_MSG);
        }
      }}
    >
      <AccountFormForm errorOnSubmit={errorOnSubmit} />
    </Formik>
  );
};

const getErrorMessageByStatus = (response: Response) => {
  switch (response.status) {
    case 400:
      return 'The username already exists';
    case 403:
      return 'Invalid activation code';
    default:
      return DEFAULT_ERR_MSG;
  }
};

const AccountFormForm: React.FC<{ errorOnSubmit?: string }> = (props) => {
  const formContext = useContext(SignUpFormContext);
  const classes = useStyles();

  const formikContext = useFormikContext<{
    username: string;
    password: string;
    repeatPassword: string;
    success: boolean;
  }>();

  const usernameInput = React.createRef<HTMLInputElement>();
  const passwordInput = React.createRef<HTMLInputElement>();

  const usernameError = formikContext.touched.username && formikContext.errors.username;
  const passwordError = formikContext.touched.password && formikContext.errors.password;
  const repeatPasswordError = formikContext.touched.repeatPassword && formikContext.errors.repeatPassword;

  return (
    <Form
      name='business-signup'
      method='post'
      data-netlify='true'
      data-netlify-recaptcha='true'
      data-netlify-honeypot='bot-field'
      onSubmit={formikContext.handleSubmit}
      className={classes.form}
    >
      <TextField
        id='username'
        ref={usernameInput}
        label='Username'
        type='text'
        name='username'
        aria-label='username'
        placeholder='Username*'
        error={Boolean(usernameError)}
        onChange={formikContext.handleChange}
        onBlur={formikContext.handleBlur}
        value={formikContext.values.username}
        helperText={usernameError}
        className={classes.textField}
      />
      <TextField
        id='password'
        ref={passwordInput}
        label='Password'
        aria-label='password'
        type='password'
        name='password'
        placeholder=''
        error={Boolean(passwordError)}
        onChange={formikContext.handleChange}
        onBlur={formikContext.handleBlur}
        value={formikContext.values.password}
        helperText={passwordError}
        className={classes.textField}
      />
      <TextField
        label='Confirm Password'
        aria-label='confirm password'
        id='repeatPassword'
        type='password'
        name='repeatPassword'
        placeholder=''
        error={Boolean(repeatPasswordError)}
        onChange={formikContext.handleChange}
        onBlur={formikContext.handleBlur}
        value={formikContext.values.repeatPassword}
        helperText={repeatPasswordError}
        className={classes.textField}
      />
      <div className={classes.center}>
        <LoadingButton
          loading={formikContext.isSubmitting}
          variant='contained'
          color='secondary'
          type='submit'
          disabled={formikContext.isSubmitting}
        >
          {formContext.activeForm === 'demo' ? 'Request Demo' : 'Create Account'}
        </LoadingButton>
        {props.errorOnSubmit && (
          <div>
            <p className={classes.error}>{props.errorOnSubmit}</p>
          </div>
        )}
      </div>
    </Form>
  );
};

export default AccountForm;
