import React from 'react';
import { Paper, makeStyles, createStyles, Theme, Typography, IconButton } from '@material-ui/core';
import CopyButton from 'common/components/CopyButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SocialMediaShareButtons from 'common/components/SocialMediaShareButtons';

interface LinkCardProps {
  offeringUri: string | undefined;
  className?: string;
}

const LinkCard: React.FC<LinkCardProps> = props => {
  const classes = useStyles();

  const shareUrl = `${process.env.REACT_APP_PORTAL}/giftcards/${props.offeringUri}`;
  const openUrl = () => window.open(shareUrl, '_blank');

  return (
    <div className={`${classes.root} ${props.className || ''}`}>
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <Typography variant='h5' component='h2' className={classes.title}>
            Shareable Gift Card Link:
          </Typography>
          <div className={classes.content}>
            <div className={classes.urlContainer}>{shareUrl}</div>
            <IconButton className={classes.openButton} aria-label='Visit gift card page' onClick={openUrl} role='link'>
              <OpenInNewIcon fontSize='small' />
            </IconButton>
            <CopyButton className={classes.button} variant='contained' text={shareUrl} />
          </div>
          <div className={classes.shareButtons}>
            <SocialMediaShareButtons />
          </div>
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary
    },
    paper: {
      'margin': 'auto',
      'padding': theme.spacing(2),
      '& h2': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 700,
        marginRight: theme.spacing(1)
      },
      [theme.breakpoints.down('sm')]: {
        'maxWidth': 'unset',
        '& h2': {
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight
        }
      }
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      maxWidth: '100%'
    },
    title: {
      flexShrink: 0
    },
    urlContainer: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden'
    },
    openButton: {
      marginRight: `${theme.spacing(2)}px !important`,
      flexGrow: 0,
      flexShrink: 0
    },
    button: {
      flexGrow: 0,
      flexShrink: 0
    },
    shareButtons: {
      marginLeft: '10px',
      height: '40px',
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      }
    }
  })
);

export default LinkCard;
