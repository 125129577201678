import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState, useEffect } from 'react';
import { SnackbarContext } from 'core/context/SnackbarContext';

const AppSnackbar: React.FC = () => {
  const [message, setMessage] = useState<string>();
  const snackbarContext = useContext(SnackbarContext);

  useEffect(() => {
    setMessage(snackbarContext.message);
  }, [snackbarContext.message]);

  const handleClose = () => setMessage(undefined);

  return (
    <Snackbar open={!!message} autoHideDuration={8000} onClose={handleClose}>
      <Alert elevation={0} variant='filled' onClose={handleClose} severity={snackbarContext.severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
