import React, { useState } from 'react';
import { Paper, Typography, Container } from '@material-ui/core';
import { makeStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import FadeTransition from 'common/components/FadeTransition';
import { SwitchTransition } from 'react-transition-group';
import GiftCard from 'common/types/GiftCard';
import ChargeCard from './components/ChargeCard';
import CodeCapture from './components/CodeCapture';
import BackButton from 'common/components/BackButton';

interface RedeemProps {
  done: () => void;
}

const Redeem: React.FC<RedeemProps> = props => {
  const [card, setCard] = useState<GiftCard | undefined>(undefined);
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper} elevation={1}>
        <BackButton back={props.done} className={classes.back} />
        <Typography variant='h4' component='h1' className={classes.title}>
          Charge Gift Card
        </Typography>
        <SwitchTransition>
          <FadeTransition key={card?.uuid}>
            {!card ? (
              <CodeCapture setCard={setCard} onCancel={props.done} />
            ) : (
              <ChargeCard card={card} done={props.done} />
            )}
          </FadeTransition>
        </SwitchTransition>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme: MuiTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)}px 0`
    }
  },
  back: {
    alignSelf: 'flex-start'
  },
  title: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    margin: '0 auto'
  },
  paper: {
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent'
    }
  }
}));

export default Redeem;
