import React, { useContext, useEffect } from 'react';
import SignUpFormContextProvider, { SignUpFormContext } from './SignUpFormContext';
import { SwitchTransition } from 'react-transition-group';
import FadeTransition from 'common/components/FadeTransition';
import BusinessForm from './BusinessForm';
import AccountForm from './AccountForm';
import { SuccessPage } from './SuccessPage';
import { makeStyles, Theme, createStyles, Typography, Grid, Container, Paper } from '@material-ui/core';
import Logo from '../../assets/logo/blacklogo.png';
import { Link } from 'react-router-dom';

export const SignupPage: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    // TODO account for calendly load error
    attachCalendlyScript();
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <div className={classes.header}>
            <img src={Logo} alt='Paneau' className={classes.logo} />
            <Typography variant='h5' component='h1' className={classes.title}>
              Giftcards
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.form}>
            <Typography className={classes.subtitle} component='h2'>
              Already have an activation code?
            </Typography>
            <SignUpFormContextProvider>
              <SignUpForm />
            </SignUpFormContextProvider>
            <Typography variant='body1'>
              Already have an account? <Link to='/login'>Log in</Link>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.calendly}>
            <Typography className={classes.subtitle} component='h2'>
              Need an activation code?
            </Typography>
            <div
              className={`${classes.calendlyWidget} calendly-inline-widget`}
              data-url='https://calendly.com/paneauonboarding/paneau-onboarding?primary_color=e088ff'
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

function attachCalendlyScript() {
  return new Promise(function(resolve, reject) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });
}

const SignUpForm: React.FC = () => {
  const { activeForm } = useContext(SignUpFormContext);
  return (
    <SwitchTransition>
      <FadeTransition key={activeForm}>
        {activeForm === 'business' || activeForm === 'demo' ? (
          <BusinessForm key='business' type={activeForm} />
        ) : activeForm === 'demosuccess' ? (
          <SuccessPage>
            <h3>
              Thank you for your interest!
              <br />
              Someone from our team will reach out shortly.
            </h3>
          </SuccessPage>
        ) : (
          <AccountForm key='account' />
        )}
      </FadeTransition>
    </SwitchTransition>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '3rem 0.5rem'
    },
    logo: {
      width: '200px'
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    subtitle: {
      fontSize: '26px',
      fontWeight: 600,
      color: '#4d5055',
      fontFamily: '"Proxima Nova", sans-serif',
      paddingBottom: '1rem',
      textAlign: 'center'
    },
    header: {
      padding: '3rem 0.5rem 1rem',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '2rem 0.5rem 1rem'
      }
    },
    calendly: {
      padding: '3rem 0.5rem',
      textAlign: 'center'
    },
    calendlyWidget: {
      'maxWidth': '100%',
      'height': '825px',
      'borderTop': '1px solid #ececec',
      '& > *': {
        fontFamily: `${theme.typography.fontFamily}!important`,
        color: '#333!important'
      },
      '& > h1': {
        color: '#333!important'
      }
    }
  })
);
