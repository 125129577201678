import React, { useState, useContext } from 'react';
import QrReader from 'react-qr-reader';
import { Typography, TextField, Button, makeStyles, Theme } from '@material-ui/core';
import useSafeFetch from 'common/hooks/useSafeFetch';
import GiftCard from 'common/types/GiftCard';
import { SnackbarContext } from 'core/context/SnackbarContext';
import LoadingButton from 'common/components/LoadingButton';

interface CodeCaptureProps {
  setCard: (card: GiftCard) => void;
  onCancel: () => void;
}

const CodeCapture: React.FC<CodeCaptureProps> = props => {
  const [code, setCode] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const { showSnackbarAlert } = useContext(SnackbarContext);
  const { get } = useSafeFetch();
  const classes = useStyles();

  const codeSubmit = () => getCard(code);

  const getCard = async (code: string | undefined | null) => {
    if (!code) return;
    try {
      setLoading(true);
      const response = await get(`${process.env.REACT_APP_PORTAL}/card?short_code=${code}`);
      if (!response.ok) {
        setError(true);
        showSnackbarAlert('Invalid code. Please try again.', 'error');
      } else {
        props.setCard(await response.json());
      }
      setLoading(false);
    } catch (e) {
      if (!e.cancelled) {
        setLoading(false);
        setError(true);
        showSnackbarAlert('Something went wrong. Please try again.', 'error');
        console.error(e);
      }
    }
  };

  const onInput = (event: any) => {
    const code = event.target.value;
    setCode(code);
  };

  const handleQrError = (e: any) => {
    showSnackbarAlert('An error occurred reading the QR Code. Please try again.', 'error');
    console.error(e);
  };

  return (
    <>
      <Typography variant='subtitle1' color='textSecondary' gutterBottom>
        Scan or Enter Code
      </Typography>
      <QrReader delay={200} onError={handleQrError} onScan={getCard} className={classes.qrScanner} />
      <Typography className={classes.or} color='textSecondary' gutterBottom>
        — or —
      </Typography>
      <div className={classes.shortCodeForm}>
        <TextField
          className={classes.chargeField}
          error={Boolean(error)}
          id='outlined-basic'
          label='Short Code'
          variant='outlined'
          color='secondary'
          onInput={onInput}
        />
        <LoadingButton loading={loading} disabled={!code} color='secondary' variant='contained' onClick={codeSubmit}>
          Submit
        </LoadingButton>
      </div>
      <div className={classes.actionBar}>
        <Button onClick={props.onCancel}>Cancel</Button>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  or: {
    padding: theme.spacing(3),
    fontSize: 14,
    margin: 0
  },
  chargeField: {
    minWidth: 100
  },
  qrScanner: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      width: '256px'
    },
    [theme.breakpoints.up('md')]: {
      width: '600px'
    }
  },
  actionBar: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  shortCodeForm: {
    'display': 'flex',
    'alignItems': 'center',
    '& > button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

export default CodeCapture;
