import { Stepper, Step, StepLabel, Container } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import StripePage from './StripePage';
import EditOffering from 'common/components/EditOffering';
import SocialMediaPage from './SocialMediaPage';
import { OfferingsContext } from 'core/context/OfferingsContext';

interface SetupProps {
  activeStep: number;
  proceedToNextStep: () => void;
  stripeIsSetup: boolean;
  offeringIsSetup: boolean;
  stripeUrl: string;
}

const Setup: React.FC<SetupProps> = props => {
  const classes = useStyles();
  const { offerings } = useContext(OfferingsContext);

  return (
    <Container className={classes.root}>
      <Stepper className={classes.stepper} alternativeLabel activeStep={props.activeStep}>
        <Step key='offering' active={0 === props.activeStep} completed={props.offeringIsSetup}>
          <StepLabel>Create your gift card</StepLabel>
        </Step>
        <Step key='stripe' active={1 === props.activeStep} completed={props.stripeIsSetup}>
          <StepLabel>Setup deposit information</StepLabel>
        </Step>
        <Step key='share' active={2 === props.activeStep} completed={false}>
          <StepLabel>Share your gift card page with customers</StepLabel>
        </Step>
      </Stepper>
      {props.activeStep === 0 ? (
        <EditOffering create done={props.proceedToNextStep} title='Set Up Your Gift Card' />
      ) : props.activeStep === 1 ? (
        <StripePage url={props.stripeUrl} />
      ) : (
        <SocialMediaPage offering={offerings?.[0]} />
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4)
    },
    stepper: {
      backgroundColor: 'transparent !important'
    }
  })
);

export default Setup;
