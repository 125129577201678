import useSafeFetch from 'common/hooks/useSafeFetch';
import React, { useState, useRef, useEffect } from 'react';

export interface UserType {
  username: string;
  uid: string;
  businessName: string;
  email: string;
}

interface UserContextType {
  user: UserType | undefined;
  error: boolean;
  loading: boolean;
  refreshUser: () => void;
}

export const UserContext = React.createContext<UserContextType>({
  user: undefined,
  error: false,
  loading: false,
  refreshUser: () => null
});

interface UserContextProviderState {
  user: UserType | undefined;
  error: boolean;
}

const UserContextProvider: React.FC = props => {
  const [state, setState] = useState<UserContextProviderState>({
    user: undefined,
    error: false
  });
  const { get } = useSafeFetch();

  const refreshUser = useRef(async () => {
    if (state.user !== undefined || state.error) {
      setState({ user: undefined, error: false });
    }
    try {
      const response = await get(`${process.env.REACT_APP_PORTAL}/me?roles=cards`, { redirectToLogin: false });
      if (response.ok) {
        const user = await response.json();
        console.log(user);
        setState({
          user: {
            username: user.username,
            businessName: user.name,
            uid: user.id,
            email: user.email
          },
          error: false
        });
      }
    } catch (e) {
      if (!e.cancelled && !e.unauthenticated) {
        setState(s => ({ ...s, error: true }));
      }
    }
  });

  useEffect(() => {
    refreshUser.current();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        refreshUser: refreshUser.current,
        error: state.error,
        loading: state.user === undefined && !state.error
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
