import React, { useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TextField, Button } from '@material-ui/core';
import { useStyles } from 'auth/styles';
import { isLocal } from 'common/utils/DevUtils';

const DEFAULT_ERR_MSG = 'An error occurred. Please try again.';

const LoginForm: React.FC = () => {
  const [errorOnSubmit, setErrorOnSubmit] = useState<string>();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        repeatPassword: ''
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Username field is required'),
        password: Yup.string().required('Password field is required')
      })}
      onSubmit={async ({ username, password }, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          setErrorOnSubmit(undefined);

          const response: Response = await submitLoginRequest(username, password);
          if (!response.ok) {
            setSubmitting(false);
            setErrorOnSubmit(response.status === 401 ? 'Incorrect username or password' : DEFAULT_ERR_MSG);
          } else if (typeof window !== 'undefined') {
            window.location.href = '/';
          }
        } catch (err) {
          setSubmitting(false);
          setErrorOnSubmit(DEFAULT_ERR_MSG);
        }
      }}
    >
      <AccountFormForm errorOnSubmit={errorOnSubmit} />
    </Formik>
  );
};

const submitLoginRequest = async (username: string, password: string) => {
  const domain = isLocal() ? process.env.REACT_APP_PORTAL : '';
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  return fetch(`${domain}/api/login`, {
    method: 'POST',
    credentials: 'include',
    // @ts-ignore
    body: new URLSearchParams(formData),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const AccountFormForm: React.FC<{ errorOnSubmit?: string }> = props => {
  const classes = useStyles();
  const formikContext = useFormikContext<{
    username: string;
    password: string;
    success: boolean;
  }>();

  const usernameInput = React.createRef<HTMLInputElement>();
  const passwordInput = React.createRef<HTMLInputElement>();
  const usernameError = formikContext.touched.username && formikContext.errors.username;
  const passwordError = formikContext.touched.password && formikContext.errors.password;

  return (
    <Form
      name='business-signup'
      method='post'
      data-netlify='true'
      data-netlify-recaptcha='true'
      data-netlify-honeypot='bot-field'
      onSubmit={formikContext.handleSubmit}
      className={classes.form}
    >
      <TextField
        className={classes.textField}
        id='username'
        ref={usernameInput}
        label='Username'
        type='text'
        name='username'
        aria-label='username'
        error={Boolean(usernameError)}
        onChange={formikContext.handleChange}
        onBlur={formikContext.handleBlur}
        value={formikContext.values.username}
        helperText={usernameError}
      />
      <TextField
        className={classes.textField}
        id='password'
        ref={passwordInput}
        label='Password'
        aria-label='password'
        type='password'
        name='password'
        placeholder=''
        error={Boolean(passwordError)}
        onChange={formikContext.handleChange}
        onBlur={formikContext.handleBlur}
        value={formikContext.values.password}
        helperText={passwordError}
      />
      <div className={classes.center}>
        <Button variant='contained' color='secondary' type='submit' disabled={formikContext.isSubmitting}>
          Login
        </Button>
        {props.errorOnSubmit && (
          <div>
            <p className={classes.error}>{props.errorOnSubmit}</p>
          </div>
        )}
      </div>
    </Form>
  );
};

export default LoginForm;
