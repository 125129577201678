import { Paper, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React from 'react';
import GiftCardOffering from 'common/types/GiftCardOffering';
import OfferingPreview from 'common/components/OfferingPreview';
import { Skeleton } from '@material-ui/lab';

interface PreviewCardProps extends Partial<GiftCardOffering> {
  editOffering: () => void;
}

const PreviewCard: React.FC<PreviewCardProps> = props => {
  const { editOffering, ...offering } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHead}>
        <Typography variant='h5' component='h2'>
          Gift Card Preview
        </Typography>
        <Button variant='contained' onClick={editOffering}>
          Edit
        </Button>
      </div>
      <div className={classes.previewContainer}>
        {offering.uuid ? (
          <OfferingPreview balanceType='original' elevation={5} offering={offering} />
        ) : (
          <Paper elevation={5} className={classes.skeleton}>
            <div>
              <Skeleton variant='circle' width={40} height={40} />
              <Skeleton variant='text' />
              <Skeleton variant='text' />
              <Skeleton variant='text' />
            </div>
            <Skeleton variant='rect' className={classes.qrSkeleton} />
          </Paper>
        )}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    paperHead: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2)
    },
    previewContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    skeleton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '310px',
      height: '450px',
      padding: theme.spacing(2)
    },
    qrSkeleton: {
      width: '150px',
      height: '150px',
      alignSelf: 'center',
      marginBottom: theme.spacing(2)
    }
  })
);

export default PreviewCard;
