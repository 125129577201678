import React, { useContext, useEffect } from 'react';
import LoginForm from './LoginForm';
import Logo from '../../assets/logo/blacklogo.png';
import { makeStyles, Theme, createStyles, Typography, Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from 'core/context/UserContext';
import Image from '../../assets/background/woman-paying-with-credit-card.jpg';

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const history = useHistory();

  const { user, loading } = userContext;
  useEffect(() => {
    if (!loading && Boolean(user)) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Grid container>
      <Grid item xs='auto' md={6} className={classes.image} />
      <Grid item xs={12} md={6}>
        <div className={classes.container}>
          <img src={Logo} alt='Paneau' className={classes.logo} />
          <Typography variant='h5' component='h1' className={classes.title}>
            Giftcards
          </Typography>
          <Typography variant='h6' component='h2'>
            Log In to Your Account
          </Typography>
          <LoginForm />
          <Typography variant='body1'>
            Don't have an account? <Link to='/signup'>Sign up</Link>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: `2rem 0.5rem`
    },
    logo: {
      width: '200px'
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6)
    },
    image: {
      backgroundImage: `url(${Image})`,
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  })
);

export default LoginPage;
