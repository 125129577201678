import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SignupPage } from './signup';
import LoginPage from './login';

export const Auth: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/signup' component={SignupPage} />
    </Switch>
  );
};
